import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//import { RootState } from 'store';
import { setLanguage, setMenu } from '../store/slices/appslice';

const useLanguage = () => {
   const dispatch = useDispatch();

   const { language } = useSelector((state) => state.app);
   const { menu } = useSelector((state) => state.app );

   const setCurrentLanguage = useCallback((language) => {
      //i18n.changeLanguage(language);
      dispatch(setLanguage(language));
   }, []);

   const setCurrentMenu = useCallback((menu) => {
      dispatch(setMenu(menu));
   }, []);

   return { language, setCurrentLanguage, menu, setCurrentMenu };
};

export default useLanguage;