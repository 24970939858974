import { createSlice } from '@reduxjs/toolkit';


const INITIAL_STATE = {
   theme: 'light',
   language: 'pt',
   menu: 'Home',
};

const appSlice = createSlice({
   name: 'app',
   initialState: INITIAL_STATE,
   reducers: {
      setTheme: (state, action) => {
         state.theme = action.payload;
      },
      setLanguage: (state, action) => {
         state.language = action.payload;
      },
      setMenu: (state, action) => {
         state.menu = action.payload;
      }
   },
});

export const { setTheme, setLanguage, setMenu } = appSlice.actions;

export default appSlice.reducer; 