import React from "react";
import * as Globals from '../modules/Globals';
import CBCard from "../components/CBCard";
import useLanguage from '../hooks/uselanguage';
import TitleHeader from "../components/TitleHeader";

const Videos = () => {
    const lang = (useLanguage().language === 'pt')?1:2;

    //var cont = 1;

    return (
        <>
            <TitleHeader />
            {
            Globals.tdata.videos.map(
                item => {
                    if( lang === item.lang )
                        return (
                            <CBCard 
                                title={item.titulo}
                                descr={item.descr}
                                img={item.video}
                                isvideo={true}
                                /*reverse={(cont++%2)===0}*/
                            />
                        )
                }
            )
            }
        </>
    );
};

export default Videos;