import { menuItemsData } from "../modules/Globals";
import MenuItems from './MenuItems';

import useLanguage from '../hooks/uselanguage';

const Navbar = () => {
    
    var depthlevel = 0;

    var menu = menuItemsData[useLanguage().language];

    return(
        <nav className="desktop-nav">
            <ul className="menus">
                {menu.map((menu, index) => {
                    depthlevel = 0;
                    return <MenuItems items={menu} key={index} depthLevel={depthlevel} />
                })}
            </ul>
        </nav>
    );
}

export default Navbar;