import { StyleSheet } from "react-native-web";
import { COLORS } from "../modules/Globals";

export const styles = StyleSheet.create({
    app : {
        flex: 1,
        backgroundColor: COLORS.mainbackground,
    },
    appheader: {
        backgroundColor: COLORS.headerbackground,
        flex: 0.1,
        flexDirection: 'row',
    },
    headerlogo : {
        flex: 0.3,
        alignItems: 'center',
        justifyContent: 'center'
    },
    headermenu: {
        flex: 0.7,
        backgroundColor: 'pink'
    },
    langselector: {
        flexDirection: 'row',
    }
});