import React, {Component} from 'react';
import {
    StyleSheet,
    View,
    Image,
} from 'react-native-web';

import Swiper from 'react-native-web-swiper';

const styles = StyleSheet.create({
    containercb: {
      flex: 1,
      backgroundColor: 'black',
      width: '100%',
      height: (window.innerWidth>=720)?'50vh':'30vh',
    },
    slideContainer: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    slide1: {
      backgroundColor: 'rgba(0,0,0,0.3)',
    },
    slide2: {
      backgroundColor: 'rgba(0,0,0,0.3)',
    },
    slide3: {
      backgroundColor: 'rgba(0,0,0,0.3)',
    },
  });

export default class CBCaroussel extends Component {

    render() {
        return(
            <View style={styles.containercb}>
                <Swiper
                    timeout={4}
                    loop={true}
                    springConfig={{ speed: 2}}
                    minDistanceForAction={0.30}
                    controlsEnabled={false}
                >
                <View style={[styles.slideContainer,styles.slide1]}>
                    <Image resizeMode='contain' style={{flex:1, width:'100%', height:'100%'}} source={require('../assets/images/logoini.png')} />
                </View> 
                <View style={[styles.slideContainer,styles.slide1]}>
                    <Image resizeMode='cover' style={{flex:1, width:'100%', height:'100%'}} source={require('../assets/images/cijlp.png')} />
                </View> 
                <View style={[styles.slideContainer,styles.slide2]}>
                <Image resizeMode='cover' style={{flex:1, width:'100%', height:'100%'}} source={require('../assets/images/ini1.jpg')} />
                </View>
                <View style={[styles.slideContainer,styles.slide3]}>
                <Image resizeMode='cover' style={{flex:1, width:'100%', height:'100%'}} source={require('../assets/images/ini2.jpeg')} />
                </View>
                <View style={[styles.slideContainer,styles.slide3]}>
                <Image resizeMode='cover' style={{flex:1, width:'100%', height:'100%'}} source={require('../assets/images/ini3.jpg')} />
                </View>
                </Swiper>
            </View>
        );
    }
}