import useLanguage from '../hooks/uselanguage';
import { Link } from "react-router-dom";

const Footer = () => {

    const lang = useLanguage().language;

    var ptext = 'Política de Privacidade';
    var fttext = "Ficha Técnica";
    var ftext = 'Siga-nos';

    var gimg = require('../assets/images/google-play-badge.png');
    var aimg = require('../assets/images/appstoreblack.png');

    if( lang !== 'pt' ) {
        ptext = 'Privacy Policy';
        fttext = 'Technical Info';
        ftext = 'Follow us';
        gimg = require('../assets/images/google-play-badge_en.png');
        aimg = require('../assets/images/appstoreblack_en.png');
    }

    const desktop = () => {
        return(
            <>
            <footer>
            <div className="footerLeft">
                <div className="footerLUp">
                    <div className="footerImg">
                    <img className="footer-img-logo" src={require('../assets/images/logo-footer.png')} />
                    <img className="footer-img-rota" src={require('../assets/images/rota_literaria_branco.png')} />
                    <img className="footer-img-cijlp" src={require('../assets/images/novoicon.png')} />
                    </div>
                </div>
                <div className="footerLDown">
                    {
                        //<img className="footer-img-barra" src={require('../assets/images/barra-logos.png')} />
                    }
                    
                    <img className="footer-img-barra" src={require('../assets/images/financiadoturismo.png')} />
                </div>
            </div>
            <div className="footerRight">
            <div><Link className="footer-a" to='/politica de privacidade' state={ptext} onClick={window.scrollTo(0,0)}>{ptext}</Link></div><br></br>
            <div><Link className="footer-a" to='/ficha técnica' state={fttext} onClick={window.scrollTo(0,0)}>{fttext}</Link></div><br></br>
            <div>
                <a href="https://play.google.com/store/apps/details?id=com.codebox.galveias&hl=pt" target="_blank"><img src={gimg} style={{width: 150}} ></img></a>
            </div>
            <div>
                <a href="https://apps.apple.com/us/app/cijlp-galveias/id6476515654?itsct=apps_box_link&itscg=30200" target="_blank"><img src={aimg} style={{width: 150}} ></img></a>
            </div>    
                <br></br>
                <p className="footerp">{ftext}</p><br></br>
                <div>
                    <img className="footer-img-insta" src={require('../assets/images/instagram-logo.png')} />
                    <a className="footer-a" alt="CIJLP-Instagram" href="http://instagram.com/galveiascentrojlp" target="_new">CIJLP - Instagram</a><br></br></div>
            </div>
            <br></br>
            </footer>
        </>
        );
    }

    const mobile = () => {
        return(
            <>
            <footer className="footer-mobile">
            <div className="footerRight-mobile">
            <div><Link className="footer-a" to='/politica de privacidade' state={ptext} onClick={window.scrollTo(0,0)}>{ptext}</Link></div>
                <br></br><br></br>
                <p className="footerp">{ftext}</p><br></br>
                <div>
                    <img className="footer-img-insta-mobile" src={require('../assets/images/instagram-logo.png')} />
                    <a className="footer-a" alt="CIJLP-Instagram" href="http://instagram.com/galveiascentrojlp" target="_new">CIJLP - Instagram</a><br></br></div>
            </div>
            <div className="footerLeft-mobile">
                <div className="footerLUp-mobile">
                    <div className="footerImg-mobile">
                    <img className="footer-img-logo-mobile" src={require('../assets/images/logo-footer.png')} />
                    <img className="footer-img-rota-mobile" src={require('../assets/images/rota_literaria_branco.png')} />
                    <img className="footer-img-cijlp-mobile" src={require('../assets/images/novoicon.png')} />
                    </div>
                </div>
                <div className="footerLDown-mobile">
                    <img className="footer-img-barra-mobile" src={require('../assets/images/barra-logos.png')} />
                </div>
            </div>
            <br></br><br></br>
            </footer>
        </>
        );
    }

    if( window.innerWidth >= 720 )
        return desktop();
    else
        return mobile();

    /*return(
        <footer>
            <div className="footerini"></div>
            <div className="footermiddle">
                <img className="footer-img-rota" src={require('../assets/images/logo-footer.png')} />
                <img className="footer-img-rota" src={require('../assets/images/rota_literaria_branco.png')} />
                <img className="footer-img-cijlp" src={require('../assets/images/novoicon.png')} />
            </div>     
            <div className="footerfirst">
                <img className="footer-img-barra" src={require('../assets/images/barra_logos.jpg')} />
            </div>
            <div className="footersecond">
                <div><Link className="footer-a" to='/politica de privacidade' state='Politica de Privacidade'>Politica de Privacidade</Link></div>
                <br></br><br></br>
                <div><a className="footer-a" alt="CIJLP-Instagram" href="http://instagram.com/galveiascentrojlp" target="_new">CIJLP - Instagram</a></div>
            </div>
        </footer>
    );*/
}

export default Footer;