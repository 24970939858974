import React from 'react';
import {Text, View} from 'react-native-web';
import { COLORS } from '../modules/Globals';
import {styles} from '../styles/Styles'
import { appData } from '../modules/Globals';

import useLanguage from '../hooks/uselanguage';
import { Language } from '../store/slices/appslice';

const fonttit = require('../assets/fonts/AWConquerorSans-Light.otf');

const LangSelector = () => {

    const { setCurrentLanguage } = useLanguage();

    const setLanguagePT = () => {
        appData.lang = 'pt';
        setCurrentLanguage('pt');
    }

    const setLanguageEN = () => {
        appData.lang = 'en';
        setCurrentLanguage('en');
    }

    return(
        <View style={styles.langselector}>
            <Text 
                style={{color: (appData.lang==='pt')?COLORS.langselected:COLORS.maintxt, fontSize:18, cursor: 'pointer'}}
                onClick={()=>{ setLanguagePT() }}
                ><span className='lang-text'>Pt </span></Text>
            <Text style={{color: COLORS.maintxt, fontSize:18}}> | </Text>
            <Text 
                style={{color: (appData.lang==='en')?COLORS.langselected:COLORS.maintxt, fontSize:18, cursor : 'pointer'}}
                onClick={()=>{ setLanguageEN() }}
                > <span className='lang-text'>En</span></Text>
        </View>
    );
}

export default LangSelector;