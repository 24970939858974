import React from "react";
import TitleHeader from "../components/TitleHeader";
import useLanguage from "../hooks/uselanguage";

const RotaLiteraria = () => {

  const pt = () => {
    return(
      <>
            A Rota Literária "Galveias", audioguiada, interativa e multimédia, permite descobrir lugares e monumentos mencionados por José Luís Peixoto nas suas obras, dando a conhecer as Galveias do escritor alentejano. O romance Galveias assume um papel fundamental na descoberta do território, das suas gentes e das suas vivências, contribuindo para traçar a identidade mais profunda da ruralidade portuguesa, através de retratos do viver e dos costumes do interior alentejano. Para fazer a rota literária convidamo-lo a instalar a aplicação móvel "CIJLP-Galveias", disponível em Android e iOS nas lojas de aplicações. O percurso inicia-se no Monte da Torre de Sepúlveda e o conteúdo áudio é reproduzido automaticamente através do reconhecimento da posição geográfica do utilizador. Ao longo da rota irá conhecer os 22 pontos de interesse relacionados com a obra do escritor galveense. Em alguns desses pontos são disponibilizadas experiências de realidade aumentada e vídeos em Língua Gestual Portuguesa.
            <br></br><br></br><br></br><br></br>
      </>
    );
  } 

  const en = () => {
    return(
      <>
            The "Galveias" Literary Route, audio-guided, interactive and multimedia, allows you to discover places and monuments mentioned by José Luís Peixoto in his works, showcasing the Alentejo writer's Galveias. The novel Galveias plays a fundamental role in the discovery of the territory, its people and their experiences, contributing to tracing the deeper identity of Portuguese rurality, through portraits of the life and customs of the Alentejo interior. To take the literary route, we invite you to install the "CIJLP-Galveias" mobile application, available on Android and iOS in application stores. The route begins at Monte da Torre de Sepúlveda and the audio content is reproduced automatically through recognition of the user's geographical position. Along the route you will discover 22 points of interest related to the work of the Galveense writer. At some of these points, augmented reality experiences and videos in Portuguese Sign Language are available.
            <br></br><br></br><br></br><br></br>
      </>
    );
  }



  return (
    <>
        <TitleHeader />
        <div className="main-div">
            {(useLanguage().language==='pt')?pt():en()}
            <img src={require('../assets/images/mapa.png')} width={'1200px'} height={'100%'} style={{"overflow-x":'hidden', "margin-top":'20px'}} />
        </div>
    </>
  );
};

{/* <iframe 
                src="http://codebox-cijlp.ddnsfree.com:10005/galveias/admin/app/mapa.php" height={'600px'} width={'100%'} 
                frameBorder="0"
            /> */}

export default RotaLiteraria;