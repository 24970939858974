import React from "react";

import useLanguage from '../hooks/uselanguage';
import TitleHeader from "../components/TitleHeader";
import { tdata } from "../modules/Globals";

const Contactos = () => {

    const tlang = (useLanguage().language === "pt")?1:2;

    const pt = () => {
        return (
            <>
            <TitleHeader />
            <div className="main-div">
            <p><b>Centro de Interpretação José Luís Peixoto</b></p>
            <p><b>Contatos :</b><br></br>
            centrodeinterpretacao@jfgalveias.pt
            </p><br></br><br></br>
            <p><b>Morada :</b><br></br>
            <a href="https://maps.app.goo.gl/cqKd4pNB3CCdj8MR6" target="_blank">Rua General José Garcia Marques Godinho<br></br>
            7400 Galveias</a>
            </p><br></br><br></br>
            <p><b>Horário :</b><br></br>
            Terça a Sábado : 10:00 às 18:00
            </p><br></br><br></br>
            {
                tdata.contactos.map(
                    item => {
                        if( item.lang === tlang )
                            return(
                                <>
                                <p>
                                    <b>{item.name}</b><br></br>
                                    <a href={item.mapslink} target="_blank" rel="noreferrer">{item.morada}</a><br></br>
                                    {item.phone}
                                </p><br></br>
                                </>
                            );
                    }
                )
            }
            </div>
            </>
        );
    }

    const en = () => {
        return (
            <>
            <TitleHeader />
            <div className="main-div">
            <p><b>Contacts :</b><br></br>
            centrodeinterpretacao@jfgalveias.pt
            </p><br></br><br></br>
            <p><b>Address :</b><br></br>
            Rua General José Garcia Marques Godinho<br></br>
            7400 Galveias
            </p><br></br><br></br>
            <p><b>Opening hours :</b><br></br>
            Tuesday to Saturday : from 10:00 to 18:00
            </p><br></br><br></br>
            {
                tdata.contactos.map(
                    item => {
                        if( item.lang === tlang )
                            return(
                                <>
                                <p>
                                    <b>{item.name}</b><br></br>
                                    <a href={item.mapslink} target="_blank" rel="noreferrer">{item.morada}</a><br></br>
                                    {item.phone}
                                </p><br></br>
                                </>
                            );
                    }
                )
            }
            </div>
            </>
        );
    }

    if( useLanguage().language === 'pt' )
        return pt();
    else
        return en();

  //return <h1>CONTACTOS page content</h1>;
};

export default Contactos;