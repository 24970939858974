import TitleHeader from "../components/TitleHeader";
import useLanguage from '../hooks/uselanguage';

const Politica = () => {

    //const lang = (useLanguage().language === 'pt')?1:2;


    return (
        <>
            <TitleHeader />
            <div className="main-div">
            <p><span><b>POL&Iacute;TICA DE PRIVACIDADE</b></span></p>
                <p>Este documento ('Pol&iacute;tica de Privacidade') descreve a pol&iacute;tica de privacidade da APP <span><i>CIJLP-GALVEIAS</i></span>, uma aplica&ccedil;&atilde;o m&oacute;vel dispon&iacute;vel para sistemas Android e iOS, doravante designada APP, propriedade da Junta de Freguesia de Galveias, sediado em Largo São Pedro, 7400-022 Galveias, e pretende, em conformidade com o Regulamento EU 2016/680 comunicar aos titulares de dados pessoais que a APP trata, tratou ou pode vir a tratar (doravante designados&rdquo;TITULARES&rdquo; ou 'UTILIZADORES'), bem como &agrave; comunidade em geral, as suas pr&aacute;ticas nesta mat&eacute;ria.</p>
                <br></br><p><span><b>Por favor, note que a APP pode ser utilizada sem </b></span><span><b><i>login</i></b></span><span><b>, ou seja, sem que UTILIZADOR se identifique.</b></span></p>
                <br></br><br></br><p>Tentou-se que a reda&ccedil;&atilde;o fosse clara, com informa&ccedil;&atilde;o dividida em blocos, por forma a facilitar a leitura, mas, em caso de d&uacute;vida, por favor, entre em contacto.</p>
                <br></br><p><span><b>1. Respons&aacute;vel pelo Tratamento</b></span></p>
                <p><span style={{'text-decoration': 'underline'}}>Junta de Freguesia de Galveias</span><br></br>
                Largo São Pedro, 7400-022 Galveias<br></br>
                +351 242 983 174</p>
                <br></br><p><span style={{'font-weight': 'bold'}}>2. Subcontratantes</span></p>
                <p><span style={{'text-decoration': 'underline'}}>CodeBox<br></br>S&eacute;rgio Peixoto, Unipessoal Lda</span><br></br>
                Rua Padre Francisco Marques, N&ordm; 55, 3&ordm; Drt&ordm; <br></br>4700-120 Braga<br></br>
                <a href='mailto:sergiopeixoto@codebox.pt' target='_blank' rel='noopener'>sergiopeixoto@codebox.pt</a></p>
                <br></br><p><span style={{'text-decoration': 'underline'}}>Your Podcast<br></br> YPT Podcast &amp; Technology Lda</span><br></br>
                Pra&ccedil;a Conde de Agrolongo, 123<br></br>4700-312 Braga<br></br>
                <a href='mailto:geral@yourpodcast.pt' target='_blank' rel='noopener'>geral@yourpodcast.pt</a></p>
                <br></br><p><span style={{'font-weight': 'bold'}}>3. Dados pessoais e finalidade do tratamento</span></p>
                <p>A app recolhe informa&ccedil;&otilde;es sobre a localiza&ccedil;&atilde;o do UTILIZADOR para mostrar a sua localiza&ccedil;&atilde;o face a pontos de interesses pr&oacute;ximos ou para o conduzir num percurso tur&iacute;stico de visita a v&aacute;rios pontos de interesse. A localiza&ccedil;&atilde;o do UTILIZADOR pode ser determinada com graus vari&aacute;veis de precis&atilde;o por:</p>
                <br></br><ul>
                <li>GPS</li>
                <li>Identifica&ccedil;&atilde;o de torres de redes m&oacute;veis ou pontos de acesso WI-FI pr&oacute;ximos</li>
                <li>Endere&ccedil;o IP</li>
                <li>Sensores do dispositivo m&oacute;vel, como o aceler&oacute;metro para determinar a velocidade de desloca&ccedil;&atilde;o, ou o girosc&oacute;pio para identificar a dire&ccedil;&atilde;o.</li>
                </ul><br></br>
                <p>As informa&ccedil;&otilde;es de localiza&ccedil;&atilde;o n&atilde;o s&atilde;o armazenadas em nenhum sistema exterior ao dispositivo m&oacute;vel do UTILIZADOR, t&atilde;o pouco cruzadas com outros dados pessoais, pelo que n&atilde;o permitem identific&aacute;-lo.</p>
                <br></br><p><span style={{'font-weight': 'bold'}}>Defini&ccedil;&otilde;es</span></p>
                <br></br><p>DADOS PESSOAIS</p>
                <p>Informa&ccedil;&atilde;o relativa a uma pessoa singular identificada ou identific&aacute;vel, direta ou indiretamente ('titular dos dados'); &eacute; considerada identific&aacute;vel uma pessoa singular que possa ser identificada, directa ou indirectamente, em especial por refer&ecirc;ncia a um identificador, como por exemplo, um nome, um n&uacute;mero de identifica&ccedil;&atilde;o, dados de localiza&ccedil;&atilde;o, identificadores por via electr&oacute;nica ou a um ou mais elementos espec&iacute;ficos da identidade f&iacute;sica, fisiol&oacute;gica, gen&eacute;tica, mental, econ&oacute;mica, cultural ou social dessa pessoa singular.</p>
                <br></br><p>TRATAMENTO</p>
                <p>Qualquer opera&ccedil;&atilde;o ou conjunto de opera&ccedil;&otilde;es efectuadas sobre os dados pessoais ou sobre conjuntos de dados pessoais, por meios automatizados ou n&atilde;o automatizados, tais como a recolha, o registo, a organiza&ccedil;&atilde;o, a estrutura&ccedil;&atilde;o, a conserva&ccedil;&atilde;o, a adapta&ccedil;&atilde;o ou altera&ccedil;&atilde;o, a recupera&ccedil;&atilde;o, a consulta, a utiliza&ccedil;&atilde;o, a divulga&ccedil;&atilde;o por transmiss&atilde;o difus&atilde;o ou qualquer outra forma de disponibiliza&ccedil;&atilde;o, a compara&ccedil;&atilde;o ou interconex&atilde;o, a limita&ccedil;&atilde;o, o apagamento ou a destrui&ccedil;&atilde;o.</p>
                <br></br><p>LIMITA&Ccedil;&Atilde;O DO TRATAMENTO</p>
                <p>A inser&ccedil;&atilde;o de uma marca nos dados pessoais conservados com o objectivo de limitar o seu tratamento futuro.</p>
                <br></br><p>RESPONS&Aacute;VEL PELO TRATAMENTO</p>
                <p>A pessoa singular ou coletiva, a autoridade p&uacute;blica, a ag&ecirc;ncia ou outro organismo</p>
                <p>que, individualmente ou em conjunto com outras, determina as finalidades e os meios de tratamento de dados pessoais e &eacute; detentora dos direitos de propriedade intelectual dos conte&uacute;dos (&aacute;udio, texto, imagem, v&iacute;deo) disponibilizados na APP.</p>
                <br></br><p>SUBCONTRATANTE</p>
                <p>Uma pessoa singular ou colectiva, a autoridade p&uacute;blica ou outro organismo que desenvolveu e produziu a APP e co-criou os conte&uacute;dos disponibilizados na &aacute;rea dos &ldquo;Percursos&rdquo; desta.</p>
                <br></br><p>CONSENTIMENTO DO TITULAR DOS DADOS</p>
                <p>Consentimento do titular dos dados, uma manifesta&ccedil;&atilde;o de vontade, livre, espec&iacute;fica, informada e expl&iacute;cita, pela qual o titular dos dados aceita, mediante declara&ccedil;&atilde;o ou acto positivo inequ&iacute;voco, que os dados pessoais que lhe dizem respeito sejam objecto de tratamento.</p>
            </div>
        </>
    );
}

export default Politica;