import React from "react";

import useLanguage from '../hooks/uselanguage';
import TitleHeader from "../components/TitleHeader";

const About = () => {

  const pt = () => {
    return(
      <>
        <TitleHeader />
        <div className="main-div">
        <h1>Centro de Interpretação José Luís Peixoto</h1>
        <br></br>
        <p>
        Inaugurado em 2024, o Centro de Interpretação José Luís Peixoto pretende estimular a descoberta de Galveias a partir da obra do escritor José Luís Peixoto, um filho da terra considerado um dos mais notáveis escritores da literatura portuguesa contemporânea. Ocupa uma antiga casa senhorial da vila de Galveias, recuperada pela Junta de Freguesia, e presta homenagem não só ao escritor alentejano, como a todos os galveenses e figuras de destaque da história das Galveias.
É no Centro de Interpretação que culmina a Rota Literária “Galveias”, audioguiada e interativa, integrada na Rede de Turismo Literário do Alentejo e Ribatejo. O projeto expositivo do Centro de Interpretação distingue-se pela sua estreita ligação à geografia da rota literária e pelas múltiplas experiências interativas disponibilizadas dentro e fora do edifício. Grande parte dos conteúdos museográficos multimédia produzidos podem ser consultados ao longo do espaço expositivo, mas também lá fora, nas Galveias, nos cantos e recantos destas colinas férteis do interior alentejano. 
        </p><br></br>
        <p>
A nova infraestrutura pretende homenagear o escritor, natural da freguesia, um dos autores de maior destaque da nossa literatura contemporânea, com obra traduzida em mais de 30 idiomas. Merecedor de Vários Prémios, dá ele próprio o nome ao Prémio Literário José Luís Peixoto, promovido pela Câmara Municipal de Ponte de Sor, desde 2007.
        </p><br></br><br></br>
        <p>A entrada é gratuita!</p>
        </div>
      </>
    );
  }

  const en = () => {
    return (
      <>
       <TitleHeader />
       <div className="main-div">
        <h1>José Luís Peixoto Interpretation Center</h1>
        <br></br>
        <p>
        The Interpretation Center dedicated to the life and work of the writer José Luís Peixoto, is located in Galveias and part of the Alentejo and Ribatejo literary tourism network.
        </p><br></br>
        <p>
The new infrastructure aims to pay homage to the writer, a native of the parish, one of the most prominent authors of our contemporary literature, with works translated into more than 30 languages. Deserving of several awards, he himself gave his name to the José Luís Peixoto Literary Prize, promoted by Ponte de Sor City Council since 2007.
        </p><br></br><br></br>
        <p>Free entry!</p>
        </div>
      </>
    );
  }

  if( useLanguage().language === 'pt' )
    return pt();
  else
    return en();

  //return <h1>About page content</h1>;
};

export default About;