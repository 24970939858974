import LangSelector from "./LangSelector";
import MobileNav from "./MobileNav";
import Navbar from './Navbar';  
import { Link } from "react-router-dom";
import {Image} from 'react-native-web';

const Header = () => {
  return (
    <header>
      <div className="nav-area">
        <div className="logo-container">
          <Link to="/" className="logo">
            <img className='App-logo'  src={require('../assets/images/icon.png')} />
          </Link>
        </div>
        <div className="menu-container">
          {/* for large screens */}
          <Navbar />

          {/* for small screens */}
          <MobileNav />

        </div>
        <div className="lang-container">
          <LangSelector />
        </div>
      </div>
    </header>
  );
};

export default Header;