import React, {Component} from 'react';
//import { Player } from 'video-react';
//import WebView from 'react-native-webview';


// const CBCard = (title, descr, img) => {
//     return(
//         <div className='cbcard-container'>
//             <h2>{title}</h2>
//             <br></br>
//             <div 
//                 style={{color:'white', textAlign:'justify', fontSize: 16}}
//                 dangerouslySetInnerHTML={{ __html : descr }}
//             />
//             <br></br>
//             <img src={img} alt={title}/>
//         </div>
//     );
// }

// export default CBCard;

export default class CBCard extends Component{
    constructor(props) {
        super(props);
        this.state = {
          loaded: true,
        };
    
        this.configs = {
            img: '',
            vid: '',
            lang: 'pt',
            urltype: 'https',
            url: 'cijlpeixoto-galveias.pt',
        };

        
    }

    componentDidMount() {

        // if( !window.location.href.includes("https:") )
        //     this.configs.urltype = 'http';

        // if( !window.location.href.includes('localhost') )
        //     this.configs.url = 'codebox-cijlp.ddnsfree.com:10005/galveias';

        console.log(this.props.img, this.props.img !== "");

        if( !this.props.isagenda )
            if( this.props.img !== "" )
                this.configs.img = this.configs.urltype + "://" + this.configs.url + "/images/" + this.props.img;
            else
                this.configs.img = "";
            //this.configs.img = this.configs.urltype + "://codebox-cijlp.ddnsfree.com:10005/galveias/images/" + this.props.img;
        else
            if( this.props.img !== "" )
                this.configs.img = this.configs.urltype + "://" + this.configs.url + "/images/" + this.props.img;
            else
                this.configs.img = "";
            //this.configs.img = this.configs.urltype + "://codebox-cijlp.ddnsfree.com:10005/galveias/images/" + this.props.img;

        //this.configs.vid = this.configs.urltype + "://codebox-cijlp.ddnsfree.com:10005/galveias/video/" + this.props.img;
        this.configs.vid = this.configs.urltype + "://" + this.configs.url + "/video/" + this.props.img;
    
        console.log(this.configs);
        this.setState({loaded: true});
    }

    rendercard1(){
        return(
            <>
            <div className='cbcard-container'>
                <div className='cbcard-content'>
                    <div className='cbcard-title'>
                        <h2>{this.props.title}</h2>
                    </div>
                    <div className='cbcard-body'>
                        <div className='cbcard-desc'
                            style={{color:'white', textAlign:'justify', fontSize: 16}}
                            dangerouslySetInnerHTML={{ __html : this.props.descr.toString().replace("justify", "left") }}
                        />
                        <div className='cbcard-img-container'>
                            <img src={this.configs.img} alt={this.props.title} className='cbcard-img'/>
                        </div>
                    </div>
                </div>
            </div>
            <br></br><br></br> 
            </>
        )
    }

    rendercard2() {
        if( this.props.isagenda ){
            var datatxt = '';
            if( this.props.item.startdate !== this.props.item.enddate ) {
                if( this.props.lang === 1 )
                    datatxt = this.props.item.startdate + ' a ' + this.props.item.enddate;
                else
                    datatxt = this.props.item.startdate + ' to ' + this.props.item.enddate;
            }
            else {
                datatxt = this.props.item.startdate;
            }
        }
        
        return(
            <>
            <div className='cbcard-container'>
                <div className='cbcard-content'>
                    <div className='cbcard-body'>
                        <div className='cbcard-title'>
                            <h2>{this.props.title}</h2>
                        </div>
                        <div className='cbcard-desc'
                            style={{textAlign:'justify', fontSize: 16}}
                            dangerouslySetInnerHTML={{ __html : this.props.descr.toString().replace("justify", "left") }}
                        />
                        {
                            (this.props.isagenda)
                            ?
                                <>
                                    <br></br>
                                    <div><b>Local : </b>{this.props.item.address}</div>
                                    <div className='cbcard-datetxt'><b>
                                        {(this.props.lang===1)?'Data':'Date'} : </b>
                                        {/*(this.props.lang===1   )?' a':' to'} {this.props.item.enddate*/datatxt}</div>
                                </>
                            :
                                null
                        }
                    </div>
                    <div className='cbcard-img-container'>
                        {
                            (this.configs.img !== "")
                            ?
                                <img src={this.configs.img} alt={this.props.title} className='cbcard-img'/>
                            :
                                null
                        }
                    </div>
                </div>
            </div>
            <br></br><br></br>
            </>
        );
    }

    rendercard3() {
        return(
            <>
            <div className='cbcard-container'>
                <div className='cbcard-content'>
                    <div className='cbcard-img-container'>
                        <img src={this.configs.img} alt={this.props.title} className='cbcard-img' />
                    </div>
                    <div className='cbcard-body'>
                        <div className='cbcard-title'>
                            <h2>{this.props.title}</h2>
                        </div>
                        <div className='cbcard-desc'
                            style={{color:'white', textAlign:'justify', fontSize: 16}}
                            dangerouslySetInnerHTML={{ __html : this.props.descr.toString().replace("justify", "left") }}
                        />
                    </div>
                </div>
            </div>
            <br></br><br></br>
            </>
        );
    }

    renderimgright() {
        if( window.innerWidth >= 720 )
            return this.rendercard2();
        else
            return this.renderGenericMobile();
    }

    renderimgleft() {
        return this.rendercard3();
    }

    rendervideocard() {
        
        //var href = this.props.descr.match(/href="([^"]*)/)[1];
        
        if( !this.props.img ) {
            this.configs.vid = this.props.descr.match(/href="([^"]*)/)[1];
            console.log("aqui : ", this.configs.vid);
        }

        return(
            <>
            <div className='cbcard-container'>
                <div className='cbcard-content-mobile'>
                    <div className='cbcard-body-video'>
                        <div className='cbcard-title'>
                            <h2>{this.props.title}</h2>
                        </div>
                        <div className='cbcard-desc'
                            style={{ textAlign: 'left', fontSize: 16}}
                            dangerouslySetInnerHTML={{ __html : this.props.descr }}
                        />
                    </div>
                    <div className='cbcard-img-container-video'>
                    {
                        (this.props.img)
                        ?
                            <video
                                style={{height:'40vh'}}
                                poster="../assets/images/icon.png"
                                src={this.configs.vid}
                                controls
                            />
                        :   <iframe
                                style={{height:'40vh', width:'100%'}}
                                src={this.configs.vid}
                                allowcors='anonymous'
                            ></iframe>
                    }
                    </div>
                </div>
            </div>
            <br></br><br></br>
            </>
        );
    }

    rendervideocardMobile() {
        
        //var href = this.props.descr.match(/href="([^"]*)/)[1];
        
        if( !this.props.img ) {
            this.configs.vid = this.props.descr.match(/href="([^"]*)/)[1];
            console.log("aqui : ", this.configs.vid);
        }

        return(
            <>
            <div className='cbcard-container'>
                <div className='cbcard-content'>
                    <div className='cbcard-title'>
                        <h2>{this.props.title}</h2>
                    </div>
                    <div className='cbcard-img-container-video'>
                    {
                        (this.props.img)
                        ?
                            <video
                                style={{height:'40vh'}}
                                poster="../assets/images/icon.png"
                                src={this.configs.vid}
                                controls
                            />
                        :   <iframe
                                style={{height:'40vh', width:'100%'}}
                                src={this.configs.vid}
                                allowcors='anonymous'
                            ></iframe>
                    }
                    </div>
                    <div className='cbcard-desc'
                        style={{ textAlign: 'left', fontSize: 16}}
                        dangerouslySetInnerHTML={{ __html : this.props.descr }}
                    />
                </div>
            </div>
            <br></br><br></br>
            </>
        );
    }

    renderGenericMobile() {
        if( this.props.isagenda ){
            var datatxt = '';
            if( this.props.item.startdate !== this.props.item.enddate ) {
                if( this.props.lang === 1 )
                    datatxt = this.props.item.startdate + ' a ' + this.props.item.enddate;
                else
                    datatxt = this.props.item.startdate + ' to ' + this.props.item.enddate;
            }
            else {
                datatxt = this.props.item.startdate;
            }
        }
        return(
            <>
            <div className='cbcard-container'>
                <div className='cbcard-content-mobile'>
                    
                        <div className='cbcard-title'>
                            <h2>{this.props.title}</h2>
                        </div>
                        <div className='cbcard-img-container-mobile'>
                            <img src={this.configs.img} alt={this.props.title} className='cbcard-img'/>
                        </div>
                        <div className='cbcard-desc'
                            style={{textAlign:'justify', fontSize: 16}}
                            dangerouslySetInnerHTML={{ __html : this.props.descr.toString().replace("justify","left") }}
                        />
                        {
                            (this.props.isagenda)
                            ?
                                <>
                                    <br></br>
                                    <div><b>Local : </b>{this.props.item.address}</div>
                                    <div className='cbcard-datetxt'><b>
                                        {(this.props.lang===1)?'Data':'Date'} : </b>
                                        {/*(this.props.lang===1   )?' a':' to'} {this.props.item.enddate*/datatxt}</div>
                                </>
                            :
                                null
                        }
                    
                </div>
            </div>
            <br></br><br></br>
            </>
        );
    } 

    render() {
        if( this.props.isvideo ) {
            if ( window.innerWidth >= 720 )
                return this.rendervideocard()
            else
                return this.rendervideocardMobile();
        }
        else {
            if( this.props.reverse )
                return this.renderimgleft();
            else
                return this.renderimgright();
        }
    }
}
