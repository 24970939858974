import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import ErrorPage from './error-page';
import About from './routes/about';
import Root from './routes/root';

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Provider } from "react-redux";
import store from './store/index';

import Contactos from './routes/contactos';
import Escritores from './routes/escritores';
import RotaLiteraria from './routes/rotaliteraria';
import Agenda from './routes/agenda';
import Bibliografia from './routes/bibliografia';
import Livros from './routes/livros';
import Premios from './routes/premios';
import Imprensa from './routes/imprensa';
import Musica from './routes/musica';
import Videos from './routes/videos';
import Fotos from './routes/fotos';
import Outros from './routes/outros';
import Biografia from './routes/biografia';
import Politica from './routes/politica';
import FichaTecnica from './routes/fichatecnica';

const router = createBrowserRouter([
  {
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <App />,
      },
      {
        path: "contactos",
        element: <Contactos />,
      },
      {
        path: "cijlp",
        element: <About />,
      },
      {
        path: "escritores",
        element: <Escritores />,
      },
      {
        path: "rotaliteraria",
        element: <RotaLiteraria />,
      },
      {
        path: "agenda",
        element: <Agenda />,
      },
      {
        path: "biografia",
        element: <Biografia />,
      },
      {
        path: "bibliografia",
        element: <Bibliografia />,
      },
      {
        path: "livros",
        element: <Livros />,
      },
      {
        path: "premios",
        element: <Premios />,
      },
      {
        path: "imprensa",
        element: <Imprensa />,
      },
      {
        path: "musica",
        element: <Musica />,
      },
      {
        path: "videos",
        element: <Videos />,
      },
      {
        path: "premios",
        element: <Premios />,
      },
      {
        path: "fotos",
        element: <Fotos />,
      },
      {
        path: "outros",
        element: <Outros />,
      },
      {
        path: "politica de privacidade",
        element: <Politica />,
      },
      {
        path: "ficha técnica",
        element: <FichaTecnica />,
      }
    ],
  },
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
    {/*<App />*/}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
