import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Root() {
  return (
    <>
    <div>
      <Header />
      <hr className="main-hr"></hr>
      <div className="content">
        <Outlet />
      </div>
      <div
        style={{width:'100vw'}}
      >
      <Footer />
      </div>
    </div>
    </>
  );
}