import React from "react";

import useLanguage from '../hooks/uselanguage';
import TitleHeader from "../components/TitleHeader";
import { appData } from "../modules/Globals";

const FichaTecnica = () => {

    const {setCurrentMenu } = useLanguage();

    if( appData.lang === 'pt' )
        setCurrentMenu('Ficha Técnica');
    else
        setCurrentMenu('Technical Info');

    const pt = () => {
        return (
            <>
            <TitleHeader />
            <div className="main-div">
            <p><b>Coordenação, Gestão e Manutenção :</b><br></br>
            Junta de Freguesia de Galveias
            </p><br></br><br></br>
            <p><b>Conteúdos :</b><br></br>
            José Luís Peixoto<br></br>
            Vânia Rego<br></br>
            Your Podcast
            </p><br></br><br></br>
            <p><b>Museologia, Museografia, Desenvolvimento e Implementação :</b><br></br>
            Junta Freguesia de Galveias<br></br>
            Your Podcast<br></br>
            Exclamação
            </p><br></br><br></br>
            <p><b>Audiovisuais :</b><br></br>
            Your Podcast
            </p><br></br><br></br>
            <p><b>Obra :</b><br></br>
            Junta de Freguesia de Galveias
            </p><br></br><br></br>
            <p><b>Cofinanciamento :</b><br></br>
            Turismo de Portugal
            </p><br></br><br></br>
            </div>
            </>
        );
    }

    const en = () => {
        return (
            <>
            <TitleHeader />
            <div className="main-div">
            <p><b>Coordination, Management and Maintenance :</b><br></br>
            Junta de Freguesia de Galveias
            </p><br></br><br></br>
            <p><b>Content :</b><br></br>
            José Luís Peixoto<br></br>
            Vânia Rego<br></br>
            Your Podcast
            </p><br></br><br></br>
            <p><b>Museology, Museography, Development and Implementation :</b><br></br>
            Junta Freguesia de Galveias<br></br>
            Your Podcast<br></br>
            Exclamação
            </p><br></br><br></br>
            <p><b>Audiovisuals :</b><br></br>
            Your Podcast
            </p><br></br><br></br>
            <p><b>Constructions :</b><br></br>
            Junta de Freguesia de Galveias
            </p><br></br><br></br>
            <p><b>Co-financing :</b><br></br>
            Turismo de Portugal
            </p><br></br><br></br>
            </div>
            </>
        );
    }

    if( useLanguage().language === 'pt' )
        return pt();
    else
        return en();

  //return <h1>CONTACTOS page content</h1>;
};

export default FichaTecnica;