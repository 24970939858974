'use strict';


class RestApiController {
    constructor(){

        this.configs = {
            //url: 'http://192.168.86.102:8101/',
            url: 'http://codebox-cijlp.ddnsfree.com:10005/galveias/api/webservice/api.php?rquest=',
        }
    }

    getRest() {
        return this;
    }

    async wsPostRequest(api, pjson) {
        //console.log(this.configs.ur)
        var res = {Success: false, Description: '', Code: ''};
        try {
          var wsurl = this.configs.url + api;
          let response = await fetch(wsurl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: pjson,
          });
          //console.log(response);
          let responseJson = [];
          try {
            responseJson = await response.json();
            console.log(JSON.stringify(responseJson));
            res.Success = responseJson.Success;
            res.Description = responseJson.Error.Description.toString();
            res.Code = responseJson.Error.Code.toString();
          } catch (error) {
            //console.log("******" + error);
            res.Success = false;
            res.Code = 404;
            res.Description = "Rest API Endpoint not found!";
            responseJson = [];
          }
        } catch  (error) {
          res.Success = false;
          res.Code = 404;
          res.Description = "Rest API Service Offline!";
        }   
        return res;
      }

      

      async wsGetRequest(api) {
        //console.log(this.configs.ur)
        var res = {Success: false, Description: '', Code: '', Data: null};
        try {
          var wsurl = this.configs.url + api;
          console.log(wsurl);
          let response = await fetch(wsurl, {
            method: 'GET',
            //mode: 'no-cors',
            headers: {
              'Content-Type': 'application/json',
              //'Origin': window.location.href,
            },
          });
          //console.log(response);
          let responseJson = [];
          try {
            //console.log(response.result());
            responseJson = await response.json();
            //console.log("AQUI");
            //console.log(responseJson);
            res.Success = true;
            //res.Description = responseJson.Error.Description.toString();
            //res.Code = responseJson.Error.Code.toString();
            res.Code = '202';
            res.Data = responseJson;
            //res = responseJson;
          } catch (error) {
            //console.log("******" + error);
            res.Success = false;
            res.Code = 404;
            res.Description = "Rest API Endpoint not found!" + error;
            responseJson = [];
          }
        } catch  (error) {
          res.Success = false;
          res.Code = 404;
          res.Description = "Rest API Service Offline!";
        }   
        return res;
      }

}

module.exports = new RestApiController();