import {testdata} from './TestData';
import RestApiController from '../controllers/RestApiController';

export var tdata = testdata;

export var isonline = false;

var rest = RestApiController.getRest();

async function getData() {
  var xpto = await rest.wsGetRequest('appdataecrai'); 
  
  if( xpto.Success ) {
    tdata = xpto.Data;
    isonline = true;
    //console.log(tdata)
  }

  //tdata = await rest.wsGetRequest('appdataecrai');
  //console.log(tdata);
}

getData();

export const COLORS = {
    mainbackground : 'black',
    maintxt : 'black',
    headerbackground : '#333',
    langselected : '#fbb040',
}

export const appData = {
  lang : 'pt',
}

export const langTranslations = {
  'pt' : {
    _textapp : 'Pagina inicial do site',
    _text_biografia: <>
    <p>
      José Luís Peixoto nasceu em Galveias, em 1974. É um dos autores de maior destaque da literatura portuguesa contemporânea. A sua obra ficcional e poética figura em dezenas de antologias, traduzidas num vasto número de idiomas, e é estudada em diversas universidades nacionais e estrangeiras.
    </p><br></br><br></br>
    <p>
      Em 2001, acompanhando um imenso reconhecimento da crítica e do público, foi atribuído o Prémio Literário José Saramago ao romance Nenhum Olhar. Em 2007, Cemitério de Pianos recebeu o Prémio Cálamo Otra Mirada, destinado ao melhor romance estrangeiro publicado em Espanha. Com Livro, venceu o prémio Libro d’Europa, atribuído em Itália ao melhor romance europeu de 2012. Em 2016, com Galveias, recebeu no Brasil o Prémio Oceanos para a melhor obra literária em língua portuguesa do ano anterior. As suas obras foram ainda finalistas de prémios internacionais como o Femina (França), o Impac Dublin (Irlanda) ou o Portugal Telecom (Brasil), entre outros.
    </p><br></br><br></br>
    <p>
      Na poesia, Gaveta de Papéis recebeu o Prémio Daniel Faria e A Criança em Ruínas recebeu o Prémio da Sociedade Portuguesa de Autores. Em 2012, publicou Dentro do Segredo – Uma viagem na Coreia do Norte, a sua primeira incursão na literatura de viagens. Os seus romances estão traduzidos em mais de trinta idiomas.
    </p>
    </>,
  },
  'en' : {
    _textapp : 'Site initial page',
    _text_biografia: <>
    <p>
      José Luís Peixoto was born in Galveias, in 1974. He is one of the most prominent authors in contemporary Portuguese literature. His fictional and poetic work appears in dozens of anthologies, translated into a vast number of languages, and is studied in several national and foreign universities.
    </p><br></br><br></br>
    <p>
    In 2001, following immense recognition from critics and the public, the José Saramago Literary Prize was awarded to the novel Não Olhar. In 2007, Cemitério de Pianos received the Cálamo Otra Mirada Prize, awarded to the best foreign novel published in Spain. With Book, he won the Libro d’Europa prize, awarded in Italy to the best European novel of 2012. In 2016, with Galveias, he received the Oceanos Prize in Brazil for the best literary work in Portuguese of the previous year. His works were also finalists for international awards such as Femina (France), Impac Dublin (Ireland) or Portugal Telecom (Brazil), among others.
    </p><br></br><br></br>
    <p>
    In poetry, Gaveta de Papéis received the Daniel Faria Prize and A Criança em Ruínas received the Prize from the Portuguese Society of Authors. In 2012, he published Inside the Secret – A Journey in North Korea, his first foray into travel literature. His novels have been translated into more than thirty languages.
    </p>
    </>,
  }
}

export const menuItemsData = {
    'pt': [
    {
      title: "José Luís Peixoto",
      url: "",
      submenu: [
        {
          title: 'Biografia',
          url: '/biografia',
        },
        {
          title: "Bibliografia",
          url: "/bibliografia",
        },
        {
          title: "Livros",
          url: "/livros",
        },
        {
          title: "Prémios",
          url: "/premios",
        },
        {
          title: "Imprensa",
          url: "/imprensa",
        },
        {
          title: "Música",
          url: "/musica",
        },
        {
          title: "Videos",
          url: "/videos",
        },
        {
          title: "Fotos",
          url: "/fotos",
        },
      ],
    },
    {
      title: 'Rota Literária',
      url: "/rotaliteraria",
    },
    /*{
      title: "Escritores",
      url: "/escritores",
    },*/
    {
      title: "Agenda",
      url: "/agenda",
    },
    {
      title: "Outros",
      url: "/outros",
    },
    {
      title: "Contactos",
      url: "/contactos",
    },
],
'en':[
{
  title: "José Luís Peixoto",
  url: "",
  submenu: [
    {
      title: 'Biographie',
      url: '/biografia',
    },
    {
      title: "Bibliography",
      url: "/bibliografia",
    },
    {
      title: "Books",
      url: "/livros",
    },
    {
      title: "Awards",
      url: "/premios",
    },
    {
      title: "Press",
      url: "/imprensa",
    },
    {
      title: "Music",
      url: "/musica",
    },
    {
      title: "Videos",
      url: "/videos",
    },
    {
      title: "Photos",
      url: "/fotos",
    },
    {
      title: "Others",
      url: "/outros",
    },
  ],
},
{
  title: 'Literary Route',
  url: "/rotaliteraria",
},
{
  title: "Schedule",
  url: "/agenda",
},
{
  title: "Contacts",
  url: "/contactos",
},
]
};