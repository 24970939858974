import React from "react";

import * as Globals from '../modules/Globals';
import useLanguage from '../hooks/uselanguage';
import TitleHeader from "../components/TitleHeader";


const Biografia = () => {

    const lang = useLanguage().language;

    const en = () => {
        return(
    <>
        <TitleHeader />
        <div className="main-div">
        { Globals.langTranslations[lang]._text_biografia }
        <br></br><br></br>
        <h1>Awards</h1>
        <br></br>
        <p>Galveias - <b>The Best Translation Award</b> 2019 (Japan)</p>
        <p>Galveias - <b>Prémio Oceanos</b> - Prémio de Literatura em Língua Portuguesa 2016 (Brazil)</p>
        <p>Livro - <b>Prémio Libro d'Europa</b> 2013 (Italy)</p>
        <p>A Criança em Ruínas - <b>Prémio da Sociedade Portuguesa de Autores</b> 2013 (Portugal)</p>
        <p>Gaveta de Papéis - <b>Prémio de Poesia Daniel Faria</b> 2008 (Portugal)</p>
        <p>Cemitério de Pianos - <b>Prémio Cálamo</b> 2007 (Spain)</p>
        <p>Nenhum Olhar - <b>Prémio Literário José Saramago</b> 2001 (Portugal)</p>
        <br></br><br></br>
        <h1>Critics Reception</h1>
        <br></br>
        <p>"One of the most surprising revelations in Portuguese literature. He is a man who knows how to write and who will continue great writers."
            <br></br><b>José Saramago</b></p><br></br>
        <p>
        "Peixoto has an extraordinary way of interpreting the world, expressed by his accurate choices of language and images."
            <br></br><b>Times Literary Supplement</b>
        </p><br></br>
        <p>
        "Peixoto has an extraordinary way of interpreting the world, expressed by his accurate choices of language and images."
            <br></br><b>Manuel Vásquez Montálban</b>
        </p><br></br>
        <p>
        "The fantastic is told with the naturalness of everyday life. The chronicle and the fable overlap, like the stories that the characters of William Faulkner or Juan Rulfo tell or witness or remain silent."
            <br></br><b>António Muñoz Molina</b>
        </p><br></br>
        <p>
        "Like Saramago, José Luís Peixoto is a writer touched by genius."
            <br></br><b>Urbano Tavares Rodrigues</b>
        </p><br></br>
        <p>
        "One of the most gifted writers in his country."
            <br></br><b>Le Monde</b>
        </p><br></br>
        <p>
        "Peixoto articulates an interesting discourse on identity and orphanhood, and in parallel creates a wonderful psychological portrait of the Portuguese rural world."
            <br></br><b>El País</b>
        </p><br></br>
        <p>
        His books have received critical references in leading international publications such as: The Independent, The Guardian, Times Literary Supplement, Esquire, Monocle, Metro, Time Out New York, San Francisco Chronicle, El País, El Mundo, ABC, Le Figaro, Le Monde, La Reppublica, Corriere de la Sera, L'Unità, Folha de S.Paulo, Estado de São Paulo, etc.
        </p>
        </div>
    </>
        );
    }
    
    
    const pt = () => {
        return(
        <>
        <TitleHeader />
        <div className="main-div">
        { Globals.langTranslations[lang]._text_biografia }
        <br></br><br></br>
        <h1>Prémios</h1>
        <br></br>
        <p>Galveias - <b>The Best Translation Award</b> 2019 (Japão)</p>
        <p>Galveias - <b>Prémio Oceanos</b> - Prémio de Literatura em Língua Portuguesa 2016 (Brasil)</p>
        <p>Livro - <b>Prémio Libro d'Europa</b> 2013 (Itália)</p>
        <p>A Criança em Ruínas - <b>Prémio da Sociedade Portuguesa de Autores</b> 2013 (Portugal)</p>
        <p>Gaveta de Papéis - <b>Prémio de Poesia Daniel Faria</b> 2008 (Portugal)</p>
        <p>Cemitério de Pianos - <b>Prémio Cálamo</b> 2007 (Espanha)</p>
        <p>Nenhum Olhar - <b>Prémio Literário José Saramago</b> 2001 (Portugal)</p>
        <br></br><br></br>
        <h1>Receção crítica</h1>
        <br></br>
        <p>"Uma das revelações mais surpreendentes da literatura portuguesa. É um homem que sabe escrever e que vai ser o continuador dos grande escritores."
            <br></br><b>José Saramago</b></p><br></br>
        <p>
        "Peixoto tem uma extraordinária forma de interpretar o mundo, expressa pelas suas escolhas certeiras de linguagem e de imagens."
            <br></br><b>Times Literary Supplement</b>
        </p><br></br>
        <p>
        "Um valor seguro da literatura portuguesa, com grande sentido de linguagem poética e grande domínio da língua portuguesa."
            <br></br><b>Manuel Vásquez Montálban</b>
        </p><br></br>
        <p>
        "O fantástico é contado com a naturalidade do quotidiano. A crónica e a fábula sobrepõem-se, como as histórias que contam ou presenciam ou calam as personagens de William Faulkner ou de Juan Rulfo."
            <br></br><b>António Muñoz Molina</b>
        </p><br></br>
        <p>
        "Como Saramago, José Luís Peixoto é um escritor tocado pelo génio."
            <br></br><b>Urbano Tavares Rodrigues</b>
        </p><br></br>
        <p>
        "Um dos escritores mais dotados do seu país."
            <br></br><b>Le Monde</b>
        </p><br></br>
        <p>
        "Peixoto articula um interessante discurso sobre a identidade e a orfandade, e elabora em paralelo um maravilhoso retrato psicológico do mundo rural português."
            <br></br><b>El País</b>
        </p><br></br>
        <p>
        Os seus livros têm tido referências críticas em publicações internacionais de referência como: The Independent, The Guardian, Times Literary Suplement, Esquire, Monocle, Metro, Time Out New York, San Francisco Chronicle, El País, El Mundo, ABC, Le Figaro, Le Monde, La Reppublica, Corriere de la Sera, L'Unità, Folha de S.Paulo, Estado de São Paulo, etc.
        </p>
        </div>
    </>
        );
    }


    if( useLanguage().language === 'pt' )
        return pt();
    else
        return en();
};

export default Biografia;