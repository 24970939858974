//import logo from './';
import './App.css';

import CBCaroussel from './components/CBCaroussel';
import useLanguage from './hooks/uselanguage';

function App() {

  

  const pt = () => {
    return(
      <>
        <div className='main-container-caroussel'>
          <CBCaroussel />
          <br></br>
            <div className="main-div">
              <div className='th-container' >
                <span className={(window.innerWidth>=720)?'th-text-normal':'th-text-mobile'}><b>Centro de Interpretação José Luís Peixoto</b></span>
              </div>
              <br></br>
              <p>
              Inaugurado em 2024, o Centro de Interpretação José Luís Peixoto pretende estimular a descoberta de Galveias a partir da obra do escritor José Luís Peixoto, um filho da terra considerado um dos mais notáveis escritores da literatura portuguesa contemporânea. Ocupa uma antiga casa senhorial da vila de Galveias, recuperada pela Junta de Freguesia, e presta homenagem não só ao escritor alentejano, como a todos os galveenses e figuras de destaque da história das Galveias.
É no Centro de Interpretação que culmina a Rota Literária “Galveias”, audioguiada e interativa, integrada na Rede de Turismo Literário do Alentejo e Ribatejo. O projeto expositivo do Centro de Interpretação distingue-se pela sua estreita ligação à geografia da rota literária e pelas múltiplas experiências interativas disponibilizadas dentro e fora do edifício. Grande parte dos conteúdos museográficos multimédia produzidos podem ser consultados ao longo do espaço expositivo, mas também lá fora, nas Galveias, nos cantos e recantos destas colinas férteis do interior alentejano. 
              </p><br></br>
              <p>
              A vida do autor serviu de fio condutor da exposição do centro de interpretação e ao longo das diferentes salas encontramos excertos da vasta obra do escritor. Os excertos selecionados por José Luís Peixoto permitem-nos associá-los diretamente a experiências do autor através de objetos, fotografias e vídeos. As fortes relações de Peixoto com a música, com as viagens, com Saramago e com Rui Nabeiro estão também representadas em vários núcleos da exposição. Entre o espólio cedido pelo escritor podemos encontrar o saxofone que o iniciou no mundo da música, uma guitarra cedida pelos Moonspell, inúmeras peças relacionadas com as viagens do autor e os prémios literários recebidos. A presença dos livros é uma constante na exposição. O autor tem as suas obras traduzidas em mais de 30 idiomas e as suas capas encontram-se expostas ao longo da exposição. Outros conteúdos podem ser consultados nos vários ecrãs interativos existentes e na Sala Imersiva é dada a conhecer as Galveias do escritor numa experiência interativa com a vila e os seus atores.
              </p><br></br><br></br>
              <p>Visite o Centro de Interpretação José Luís Peixoto, de terça a sábado entre as 10h00 e as 18h00</p>
            </div>
        </div>
        <br></br><br></br><br></br><br></br><br></br><br></br><br></br>
      </>
    );
  }

  const en = () => {
    return (
      <>
       <div className='main-container-caroussel'>
          <CBCaroussel />
          <br></br>
          <div className="main-div">
            <div className='th-container' >
              <span className={(window.innerWidth>=720)?'th-text-normal':'th-text-mobile'}><b>José Luís Peixoto Interpretation Center</b></span>
            </div>
            <br></br>
            <p>
            Opened in 2024, the José Luís Peixoto Interpretation Center aims to stimulate the discovery of Galveias based on the work of the writer José Luís Peixoto, a son of the soil considered one of the most notable writers of contemporary Portuguese literature. It occupies an old manor house in the town of Galveias, restored by the Parish Council, and pays tribute not only to the Alentejo writer, but to all Galveans and prominent figures in the history of Galveias.
It is at the Interpretation Center that the “Galveias” Literary Route culminates, audio-guided and interactive, integrated into the Alentejo and Ribatejo Literary Tourism Network. The Interpretation Center's exhibition project is distinguished by its close connection to the geography of the literary route and by the multiple interactive experiences available inside and outside the building. Much of the multimedia museographic content produced can be consulted throughout the exhibition space, but also outside, in Galveias, in the nooks and crannies of these fertile hills in the Alentejo interior.
            </p><br></br>
            <p>
            The author's life served as the guiding thread of the interpretation center's exhibition and throughout the different rooms we found excerpts from the writer's vast work. The excerpts selected by José Luís Peixoto allow us to directly associate them with the author's experiences through objects, photographs and videos. Peixoto's strong relationships with music, travel, Saramago and Rui Nabeiro are also represented in several sections of the exhibition. Among the assets donated by the writer we can find the saxophone that introduced him into the world of music, a guitar donated by Moonspell, numerous pieces related to the author's travels and the literary awards he received. The presence of books is a constant in the exhibition. The author has his works translated into more than 30 languages and his covers are displayed throughout the exhibition. Other content can be consulted on the various existing interactive screens and in the Immersive Room you can discover the writer's Galveias in an interactive experience with the town and its actors.
            </p><br></br><br></br>
            <p>Visit the José Luís Peixoto Interpretation Center, from Tuesday to Saturday between 10am and 6pm</p>
          </div>
      </div>
      </>
    );
  }

  if( useLanguage().language === 'pt' )
   return pt();
  else
    return en();

  // return (
  //   <div className='main-container-caroussel'>
  //     <CBCaroussel />
  //     <br></br>
  //   </div>
  // );

  // return (
  //   <View className="App"
  //     style={[styles.app,{width: width, height: height}]}
  //   >
  //     <View
  //       style={[styles.appheader]}
  //     >
  //       <View style={styles.headerlogo}>
  //         <Text style={{color: 'white'}}>Logo here...</Text>
  //       </View>
  //       <View style={styles.headermenu}>
  //         <Header />
  //       </View>
  //     </View>
  //     <View 
  //       style={{flex:0.9}}
  //     >
  //     </View>
  //   </View>
  // )
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
}

export default App;
